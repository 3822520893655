export class Analytics {

	constructor() {
		const elements: HTMLCollectionOf<HTMLAnchorElement> = document.getElementsByTagName('a');
		for (let i: number = 0; i < elements.length; i++) {
			const element: HTMLAnchorElement = elements[i];
			if (Analytics.CheckElement(element)) {
				element.addEventListener('click', this.LogDocumentClick);
			}
		}

	}

	public LogError: (ex: any, fatal?: boolean) => Promise<void> = (ex: any, fatal: boolean = false) => {
		return new Promise<void>((event_callback) => {
			const description: string = ex && ex.message ? ex.message : ex.toString();

			gtag('event', 'exception', {
				description,
				fatal,
				event_callback
			});
		});
	};

	public LogLogin: (method?: string) => Promise<void> = (method: string = 'Google') => {
		return new Promise<void>((event_callback) => {
			gtag('event', 'login', {
				method,
				event_callback
			});
		});
	};

	public LogSignup: (method?: string) => Promise<void> = (method: string = 'Google') => {
		return new Promise<void>((event_callback) => {
			gtag('event', 'sign_up', {
				method,
				event_callback
			});
		});
	};

	public LogUser: (userId: string) => Promise<void> = (userId: string) => {
		return new Promise<void>((event_callback) => {
			gtag('set', {
				user_id: userId,
				event_callback
			});
		});
	};

	public LogSearch: (search_term: string) => Promise<void> = (search_term: string) => {
		return new Promise<void>((event_callback) => {
			gtag('event', 'search', {
				search_term,
				event_callback
			});
		});
	};

	public LogStopSearch: (search_term: string) => Promise<void> = (search_term: string) => {
		return new Promise<void>((event_callback) => {
			gtag('event', 'stop_search', {
				event_category: 'engagement',
				event_label: search_term,
				event_callback
			});
		});
	};

	public LogSiteSearch: () => boolean = () => {
		const element: HTMLInputElement = document.getElementById('query') as HTMLInputElement;
		const search_term: string = element.value;
		this.LogSearch(search_term);
		return true;
	};

	public LogViewSearchResults: (search_term: string) => Promise<void> = (search_term: string) => {
		return new Promise<void>((event_callback) => {
			gtag('event', 'view_search_results', {
				search_term,
				event_callback
			});
		});
	};

	public LogClick: (event_label: string, event_category: string) => Promise<void> = (event_label: string, event_category: string) =>
		new Promise<void>(event_callback => {
			gtag('event', 'click', {
				event_category,
				event_label,
				event_callback
			});
		});

	public LogClickValue: (event_label: string, event_category: string, value: number) => Promise<void> = (event_label: string, event_category: string, value: number) =>
		new Promise<void>(event_callback => {
			gtag('event', 'click', {
				event_category,
				event_label,
				event_callback,
				value
			});
		});

	public LogDocumentClick: (event: MouseEvent) => void = (event: MouseEvent) => {
		const target: HTMLAnchorElement = event.target as HTMLAnchorElement;
		if (target) {
			this.LogAnchorDocument(target);
		}
	};

	public LogAnchorDocument: (element: HTMLAnchorElement) => void = (element: HTMLAnchorElement) => {

		interface IBuildLog {
			eventLabel: string;
			eventCategory: string;
			doctype: string;
		}

		const buildLog: (element: HTMLAnchorElement) => IBuildLog = (a: HTMLAnchorElement) => {

			const splits: string[] = a.href.split(/\//g);
			const name: string = splits[splits.length - 1];

			let eventLabel: string = name;
			if (a.download && a.download.length > 0) {
				eventLabel = a.download;
			}

			const eventCategory: string = Analytics.GetLocation();

			const nameSplits: string[] = name.split(/\./g);
			const doctype: string = nameSplits[nameSplits.length - 1];

			return { eventLabel, eventCategory, doctype };
		};

		try {
			const { eventLabel, eventCategory, doctype } = buildLog(element);
			this.LogDocument(eventLabel, eventCategory, doctype);
		} catch (ex) {
			console.warn('couldn\'t log click', ex);
			this.LogError(ex, false);
		}
	};

	private LogDocument: (event_label: string, event_category: string, doctype: string) => Promise<void> = (event_label: string, event_category: string, doctype: string) => {
		return new Promise<void>((event_callback) => {
			gtag('event', 'click', {
				doctype,
				event_category,
				event_label,
				event_callback
			});
		});
	};

	private static CheckElement: (element: HTMLAnchorElement) => boolean = (element: HTMLAnchorElement) => {
		const href: string = element.href;
		if (href.endsWith('.pdf') ||
			href.endsWith('.jpeg') ||
			href.endsWith('.jpg') ||
			href.endsWith('.png')) {
			return true;
		}
		return false;
	};

	public static GetLocation: () => string = () => {
		const cannonicalUrl = document.getElementById('canonical-url') as HTMLAnchorElement;
		return cannonicalUrl.href;
	}

}
