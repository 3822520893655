import { Analytics } from "./analytics/analytics";

(() => {
	if (!window.mtd) {
		window.mtd = {} as any;
	}
	if (!window.mtd.analytics) {
		window.mtd.analytics = new Analytics();
	}
})();
